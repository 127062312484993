import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useSettings } from '@wix/tpa-settings/react';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { WixComments } from '@wix/comments-ooi-client';
import { WixCommentsTranslationsProvider } from '@wix/comments-ooi-translations-provider';

import type { IFeedItem } from 'api/feed/types';
import {
  selectGroupSlugById,
  selectIsJoinedGroupMember,
} from 'store/selectors';

import * as common from 'settings/common';
import { useHttpClient, useRelativeTimeFormatter } from 'common/hooks';

import { ErrorState } from 'wui/ErrorState';
import { CardContent } from 'wui/CardContent';
import { type TPAComponentProps } from 'wui/types';

import { useCommentsPluginConfig } from './useCommentsPluginConfig';

import classes from './Comments.scss';

interface ICommentsProps extends TPAComponentProps {
  auto?: boolean;
  item: IFeedItem;
}

export function Comments(props: ICommentsProps) {
  const { className, auto = false, item, ...rest } = props;
  const httpClient = useHttpClient();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const rtf = useRelativeTimeFormatter();
  const router = useRouter();
  const settings = useSettings();
  const { params } = useCurrentStateAndParams();

  const groupId = item.applicationContext?.contextId as string;

  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const slug = useSelector(selectGroupSlugById(groupId));
  const pluginConfig = useCommentsPluginConfig(groupId);
  const showBadges = settings.get(common.settings.showMembersBadges);

  return (
    <CardContent
      disableSideGutters={isMobile}
      className={cls(classes.root, className)}
      {...rest}
    >
      <ErrorMonitorBoundary
        fallback={
          <ErrorState
            title={t('groups-web.error-state.title')}
            subtitle={t('groups-web.error-state.subtitle')}
            actionLabel={t('groups-web.error-state.retry.label')}
            variant="section"
          />
        }
      >
        <WixCommentsTranslationsProvider>
          <WixComments
            hideHeader
            hideBadge={!showBadges}
            blackAndWhiteModals
            hideZeroCommentsEmptyState
            disableResourceCleanup={!auto}
            isLocked={false}
            httpClient={httpClient}
            disableResourceAutoFetching={!auto}
            disableWriteBeforeLogin={false}
            pluginConfig={pluginConfig}
            resourceId={item.feedItemId as string}
            formatRelativeTime={rtf}
            isPreconditionRequiredForAction={() => !isJoined}
            deepLink={{
              commentId: params.commentId,
              generateLink: handleGenerateLink,
            }}
            ctxFields={{
              contextId: groupId,
              contextType: 'postId',
            }}
            pagination={{
              replyShowMoreLimit: 10,
              initialPage: {
                commentLimit: 1,
                replyLimit: 0,
              },
              pagination: {
                commentLimit: 20,
                replyLimit: 0,
              },
            }}
          />
        </WixCommentsTranslationsProvider>
      </ErrorMonitorBoundary>
    </CardContent>
  );

  function handleGenerateLink(commentId: string) {
    return router.href(
      'group.discussion.post',
      {
        slug,
        commentId,
        feedItemId: item.feedItemId,
      },
      {
        absolute: true,
      },
    );
  }
}

Comments.displayName = 'Comments';
